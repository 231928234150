import { graphql, Link } from 'gatsby'
import React from 'react'
import Layout from 'components/layout'
import Meta from 'components/meta'
import get from 'lodash/get'

class Blog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      blogs: get(this.props.data, 'allContentfulBlog.nodes'),
      popularPosts: get(this.props.data, 'allContentfulBlog.nodes'),
      tags: get(this.props.data, 'allContentfulBlogTags.nodes'),
      categories: get(this.props.data, 'allContentfulBlogCategories.nodes'),
      pageData: get(this.props.data, 'contentfulLimitlessLearning'),
      search: '',
      inputSearch: '',
      searchValue: '',
      searchType: 1,
      activeNoti: false,
    }
  }

  closeNoti = () => {
    this.setState({ activeNoti: true })
  }
  renderPopularPosts = (blog, index) => {
    return (
      <li key={index}>
        <Link to={'/limitless-learning/' + blog.slug}>
          <img
            src={blog.image ? blog.image.file.url : ''}
            alt={
              blog.image && blog.image.description ? blog.image.description : ''
            }
          />{' '}
          <span className="content">
            <strong>{blog.title}</strong> <small>{blog.updatedAt}</small>
          </span>
        </Link>
      </li>
    )
  }

  renderFeaturedBlogs = (item, index) => {
    if (item.isFeatured == true) {
      return (
        <article className="base-featured w-100" key={index}>
          <div className="featured">Featured</div>
          <div className="directories__featured-item-container">
            <div className="featured-card-image">
              {item.videoUrl ? (
                <iframe
                  height="200"
                  src={item.videoUrl}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              ) : (
                ''
              )}
              {item.image ? (
                <figure className="">
                  <Link to={'/limitless-learning/' + item.slug}>
                    <img
                      src={item.image ? item.image.file.url : ''}
                      alt={
                        item.image && item.image.description
                          ? item.image.description
                          : ''
                      }
                      itemProp="image"
                    />
                  </Link>
                </figure>
              ) : (
                ''
              )}
            </div>
            <div className="featured-card-content">
              <h2 className="mb-2">
                <Link
                  to={'/limitless-learning/' + item.slug}
                  rel="bookmark"
                  itemProp="url"
                >
                  {item.title}
                </Link>
              </h2>
              <p>{item.shortDescription.shortDescription}</p>
              <div className="">
                <Link
                  to={'/limitless-learning/' + item.slug}
                  className="read-more-link btn btn-fancy"
                >
                  Read more <i className="feather-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </article>
      )
    }
  }

  renderBlogs = (item, index) => {
    if (item.isFeatured == false) {
      return (
        <article
          className="post has-post-thumbnail text-center knowledge-base"
          itemScope="itemScope"
          itemType="http://schema.org/BlogPosting"
          itemProp="blogPost"
          key={index}
        >
          <figure className="">
            <Link to={'/limitless-learning/' + item.slug}>
              <img
                src={item.image ? item.image.file.url : ''}
                alt={
                  item.image && item.image.description
                    ? item.image.description
                    : ''
                }
                itemProp="image"
              />
            </Link>
          </figure>
          <header className="entry-header pt-4 mb-0">
            <h4 className="entry-title" itemProp="headline">
              <Link
                to={'/limitless-learning/' + item.slug}
                rel="bookmark"
                itemProp="url"
              >
                {item.title}
              </Link>
            </h4>
            {/* <div className="entry-meta text-center">
            <span className="entry-time meta-date">
              <i className="feather-calendar"></i>{' '}
              <time
                itemProp="datePublished"
                dateTime="2018-09-22T05:50:47+00:00"
              >
                {item.updatedAt}{' '}
              </time>
            </span>
            <span className="meta-reply">
              <i className="feather-message-circle"></i> {item.category.name}
            </span>
            <span className="vcard author entry-author">
              <i className="feather-user"></i> {item.author}
            </span>
          </div> */}
          </header>

          <div className="entry-content" itemProp="description">
            <p>{item.shortDescription.shortDescription}</p>
          </div>
          <footer className="entry-footer clearfix">
            <Link
              to={'/limitless-learning/' + item.slug}
              className="read-more-link btn btn-fancy"
            >
              Read more <i className="feather-arrow-right"></i>
            </Link>
          </footer>
        </article>
      )
    }
  }

  onchange = e => {
    if (e.target.value) {
      this.setState({ inputSearch: e.target.value })
    } else {
      this.setState({ search: '' })
    }
  }

  onFilterClick = (e, value, type) => {
    this.setState({ searchValue: value, searchType: type })
  }

  clearFilter = e => {
    this.setState({ searchValue: '' })
  }

  onSearchClick = e => {
    this.setState({ search: this.state.inputSearch })
  }

  render() {
    const { location, data } = this.props
    const {
      blogs,
      search,
      tags,
      searchType,
      searchValue,
      categories,
      pageData
    } = this.state

    const popularPosts = blogs.filter(item => {
      return (
        item.title.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        item.shortDescription.shortDescription
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1
      )
    })
    const posts = blogs.filter(item => {
      if (searchType == 1) {
        if (item.tag && item.tag.tag)
          return (
            item.tag.tag.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
          )
      } else {
        if (item.category && item.category.name)
          return (
            item.category.name
              .toLowerCase()
              .indexOf(searchValue.toLowerCase()) !== -1
          )
      }
    })
    const siteMetadata = {
      title: 'Limitless Learning | Limitless With Jess',
      description: 'Gatsby starter for bootstrap a blog',
      siteUrl: 'https://limitlesswithjess.com/limitless-learning',
    }
    return (
      <Layout location={location}>
        <Meta site={siteMetadata} />
        <div className="content-wrapper">
        <div className="mb-5">
        <div className="page-title pt-5 pb-5 blog-list mb-0">
          {/* <div
              className={
                this.state.activeNoti === true
                  ? 'd-none'
                  : 'm-sitebanner-regular'
              }
            >
              <div className="m-sitebanner">
                  <span className="d-flex flex-wrap align-items-center justify-content-center">
                  {pageData.notes ? pageData.notes.notes : ''} <i className="icon ion-ios-arrow-right ml-2"></i>
                  </span>
              </div>
              <i className="feather-x close-btn c-p" onClick={this.closeNoti}></i>
            </div> */}
            <div className="container">
              <h1 className="entry-title heading-effect">
                <span className="title text-white">Limitless Learning</span>
              </h1>
              <nav
                className="breadcrumbs"
                itemScope
                itemType="http://schema.org/BreadcrumbList"
              >
                <meta itemProp="numberOfItems" content="2" />
                <meta itemProp="itemListOrder" content="Ascending" />
                <ol className="breadcrumb">
                  <li
                    itemProp="itemListElement"
                    itemScope
                    itemType="http://schema.org/ListItem"
                    className="breadcrumb-item"
                  >
                    <Link to="/">
                      <span itemProp="name">Home</span>
                    </Link>
                    <meta itemProp="position" content="1" />
                  </li>
                  <li
                    itemProp="itemListElement"
                    itemScope
                    itemType="http://schema.org/ListItem"
                    className="breadcrumb-item active"
                  >
                    <span itemProp="name" className="text-white">Limitless Learning</span>
                    <meta itemProp="position" content="2" />
                  </li>
                </ol>
              </nav>
             
            </div>
            
          </div>
          <div
              className={
                this.state.activeNoti === true
                  ? 'd-none'
                  : 'm-sitebanner-regular'
              }
            >
              <div className="m-sitebanner">
                  <span className="d-flex flex-wrap align-items-center justify-content-center">
                  {pageData.notes ? pageData.notes.notes : ''} <i className="icon ion-ios-arrow-right ml-2"></i>
                  </span>
              </div>
              <i className="feather-x close-btn c-p" onClick={this.closeNoti}></i>
            </div>
        </div>
          
          <div className="container main-wrapper clearfix">
            <div className="widget-list row">
            
              <aside
                className="col-lg-3 sidebar widget-area clearfix"
                itemScope="itemScope"
                itemType="http://schema.org/WPSideBar"
              >
                {/* <section className="widget widget_search">
                  <h6 className="widget-title">Search</h6>
                  <form role="search" className="search-form">
                    <label>
                      <span className="screen-reader-text">Search for:</span>
                      <input
                        type="search"
                        className="search-field"
                        placeholder="Search …"
                        defaultValue=""
                        aria-label="search"
                        onChange={this.onchange}
                      />
                      <i className="feather-search mr-2 search-block" onClick={e => this.onSearchClick(e)}></i>
                    </label>
                    <button
                      type="button"
                      
                      className="search-submit"
                    >
                      <span className="screena-reader-text">Search</span>
                    </button>
                  </form>
                </section> */}
                {/* <section className="widget widget_uni_posts">
                  <h6 className="widget-title">Popular Posts</h6>
                  <ul>
                    {popularPosts.map((item, index) => {
                      if (index < 3) {
                        return this.renderPopularPosts(item, index)
                      }
                    })}
                  </ul>
                </section> */}
                <section className="widget widget_categories">
                  <h6 className="widget-title">Categories</h6>
                  <ul>
                    <li
                      className="cat-item cat-item-11 c-p"
                      onClick={e => this.clearFilter(e)}
                    >
                      All
                    </li>
                    {categories.map((item, index) => {
                      return (
                        <li className="cat-item cat-item-11 c-p" key={index}>
                          <span
                            onClick={event =>
                              this.onFilterClick(event, item.name, 2)
                            }
                          >
                            {item.name}
                          </span>{' '}
                        </li>
                      )
                    })}
                  </ul>
                </section>
                <section className="widget widget_tag_cloud">
                  <h6 className="widget-title">Tags</h6>

                  <div className="tagcloud">
                    <ul className="wp-tag-cloud">
                      <li onClick={e => this.clearFilter(e)}>
                        <span className="tag-cloud-link c-p">All</span>
                      </li>
                      {tags.map((item, index) => {
                        return (
                          <li key={index}>
                            <span
                              onClick={event =>
                                this.onFilterClick(event, item.tag, 1)
                              }
                              className="tag-cloud-link c-p"
                              style={{ fontSize: '1em' }}
                            >
                              {item.tag}
                            </span>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </section>
              </aside>
              <main
                className="col-lg-9 main-content clearfix blog-content knowledge-based-wrap"
                itemScope="itemScope"
                itemType="http://schema.org/Blog"
              >
                {posts && posts.length
                  ? posts.map((item, index) => {
                      return this.renderFeaturedBlogs(item, index)
                    })
                  : ''}
                <div className="featured-block">
                  {posts && posts.length ? (
                    posts.map((item, index) => {
                      return this.renderBlogs(item, index)
                    })
                  ) : (
                    <article>
                      <div className="entry-content" itemProp="description">
                        <p>No posts found.</p>
                      </div>
                    </article>
                  )}
                </div>
              </main>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Blog

export const query = graphql`
  {
    allContentfulBlog(sort: { fields: createdAt, order: DESC }) {
      nodes {
        tag {
          tag
        }
        category {
          name
        }
        title
        slug
        shortDescription {
          shortDescription
        }
        longDescription {
          longDescription
        }
        image {
          file {
            url
          }
          description
        }
        author
        isFeatured
        videoUrl
        updatedAt(formatString: "MMM Do YYYY")
      }
    }
    allContentfulBlogTags {
      nodes {
        tag
      }
    }
    allContentfulBlogCategories {
      nodes {
        name
      }
    }
    contentfulLimitlessLearning {
      notes {
        notes
      }
    }
  }
`
